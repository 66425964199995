import React, { useEffect, useState } from "react";

import { Hidden, Typography } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";

import CouponSection from "../../components/@pages/payments/CouponSection";
import PaymentMethods from "../../components/@pages/payments/PaymentMethods";
import { setAvailablePaymentMethods } from "../../components/@pages/payments/redux/actions";
import AlertMessage from "../../components/common/AlertMessage";
import BottomNavButtons from "../../components/common/BottomNavButtons";
import StickyBtnWrapper from "../../components/common/StickyBtnWrapper";
import { addCompletedPage } from "../../components/routes/redux/actions";
import Stepper from "../../components/Stepper";
import MobileStepper from "../../components/Stepper/MobileStepper";
import ContentContainer from "../../containers/ContentContainer";
import ContentSection from "../../containers/ContentSection";
import OrderInfoSection from "../../containers/OrderInfoSection";
import PageWrapper from "../../containers/PageWrapper";
import SecondaryThemeProvider from "../../containers/SecondaryThemeProvider";
import {
  logError,
  logInfo,
  logWarn,
  transferLogsServerAndClearLocalLogs,
} from "../../services/logging";
import { confirmOrder } from "../../services/orderService";
import FacebookPixel from "../../utils/analytics/FacebookPixel";
import { PAYMENT_METHOD_CARD } from "../../utils/constants";
import { getOrderData } from "../../utils/order";
import { getLocalStore, setLocalStore } from "../../utils/storage/localStorage";
import { setCustomerDetails } from "../../components/@pages/contact/redux/actions";
import TicktockPixel from "../../utils/TicktockPixel";

const PaymentsPage = () => {
  const [alertMessage, setAlertMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { params } = useRouteMatch();
  const dispatch = useDispatch();
  const location = useLocation();
  const {
    selectedDeliveryMethod,
    selectedDeliveryTimeType,
    selectedDateTime,
    selectedDeliveryData,
    orderDiscounts,
    deliveryLocation,
    totalPriceItems,
    totalDiscount,
    discountAttachedCartItems,
    orderNotes,
    cartItems,
    appliedDiscounts,
  } = useSelector((state) => state.cart);

  const { selectedPaymentMethod } = useSelector((state) => state.payments);
  const { serviceCharge, extraCharges, storeInfo } = useSelector(
    (state) => state.store,
  );
  const {
    customer: { firstName, lastName, phoneNumber, email, marketing },
  } = useSelector((state) => state.contact);

  useEffect(() => {
    dispatch(setAvailablePaymentMethods(selectedDeliveryData.paymentTypes));
  }, [selectedDeliveryData, dispatch]);

  const handleContinue = async () => {
    setIsLoading(true);
    if (!selectedPaymentMethod) {
      setIsLoading(false);
      return setAlertMessage("Please select payment method");
    }
    const orderId = getLocalStore("orderId");
    const sessionVersion = getLocalStore("sessionVersion");
    if (!orderId) {
      logError({
        message: "WARNING | order id miss match",
        orderId,
        sessionVersion,
      });
      setIsLoading(false);
      return setAlertMessage(
        "Order id miss match. Please restart the order from the home page",
      );
    }

    if (selectedPaymentMethod === PAYMENT_METHOD_CARD) {
      dispatch(addCompletedPage("/confirm"));
      logInfo({
        message: "card payment selected. redirecting to confirm page",
      });
      transferLogsServerAndClearLocalLogs();
      setIsLoading(false);
      return history.push({
        pathname: `/location/${params.locationId}/confirm`,
        search: location.search,
      });
    }

    const data = await getOrderData(
      sessionVersion,
      cartItems,
      discountAttachedCartItems,
      orderDiscounts,
      selectedDeliveryData,
      totalPriceItems,
      selectedDateTime,
      selectedDeliveryTimeType,
      selectedDeliveryMethod,
      firstName,
      lastName,
      phoneNumber,
      email,
      marketing,
      deliveryLocation,
      selectedPaymentMethod,
      totalDiscount,
      orderNotes,
      serviceCharge,
      extraCharges,
      appliedDiscounts,
      storeInfo,
    );
    logInfo({
      message: "cash payment selected. making confirm order request.",
      // orderConfirmData: data,
      orderId,
      locationId: params.locationId,
    });
    setLocalStore("paymentAmount", data.paymentAmount);
    try {
      const res = await confirmOrder(params.locationId, orderId, data);
      if (res.status === 200) {
        setLocalStore("sessionVersion", res?.data?.data?.orderSessionVersion);
        dispatch(addCompletedPage("/confirm"));
        console.log("sddddddddddd45", res?.data)
        logInfo({
          message: "confirm order request success. redirecting to confirm page",
          confirmRequestResponse: res?.data,
        });
        FacebookPixel.addPaymentInfo(cartItems, data.paymentAmount, "CASH");
        // if (storeInfo?.tikTokAnalyticsId) {
        //   TicktockPixel.addPaymentInfo(cartItems, data.paymentAmount);
        // }
        setIsLoading(false);
        return history.push({
          pathname: `/location/${params.locationId}/confirm`,
          search: location.search,
        });
      }
      logWarn({
        message: "WARNING | confirm order request not received 200 OK response",
        confirmRequestResponse: res?.data,
      });
      setIsLoading(false);
      return setAlertMessage(
        "Not received 200 OK. Please check connection and retry.",
      );
    } catch (error) {
      logError({
        message: "ERROR | confirm order request failed",
        error,
        errorRes: error?.response,
      });
      if (error?.response?.data?.message) {
        setIsLoading(false);
        return setAlertMessage(error?.response?.data?.message);
      }
      setIsLoading(false);
      return setAlertMessage(
        "Error placing the order. Please check your connection.",
      );
    }
  };
  
  const handleBackward = () => {
    const customer = { firstName, lastName, phoneNumber, email, marketing };
    dispatch(addCompletedPage("/contact"));
    dispatch(setCustomerDetails(customer));
    history.push({
      pathname: `/location/${params.locationId}/contact`,
      search: location.search,
    });
  };
  
  return (
    <PageWrapper>
      <AlertMessage
        message={alertMessage}
        setMessage={setAlertMessage}
        severity="error"
      />
      <OrderInfoSection />
      <SecondaryThemeProvider>
        <ContentSection>
          <MobileStepper
            activeStep={3}
            handleNext={handleContinue}
            handleBack={handleBackward}
            isLoading={isLoading}
          />
          <Stepper activeStep={2} />
          <ContentContainer>
            <Typography
              gutterBottom
              variant="h3"
              component="div"
              color="textPrimary"
              style={{ margin: 8 }}
            >
              Choose you payment methods
            </Typography>
            <PaymentMethods />
            <CouponSection handleContinue={handleContinue} />
          </ContentContainer>
          <Hidden xsDown>
            <BottomNavButtons
              handleBackward={handleBackward}
              handleContinue={handleContinue}
              continueButtonLabel="Continue"
            />
          </Hidden>

          <Hidden smUp>
            <StickyBtnWrapper>
              <BottomNavButtons
                handleBackward={handleBackward}
                handleContinue={handleContinue}
                continueButtonLabel="Continue"
                continueButtonIsLoading={isLoading}
              />
            </StickyBtnWrapper>
          </Hidden>
        </ContentSection>
      </SecondaryThemeProvider>
    </PageWrapper>
  );
};

PaymentsPage.propTypes = {};
PaymentsPage.defaultProps = {};

export default PaymentsPage;

import axiosClient from "./client";

// eslint-disable-next-line import/prefer-default-export
export async function getCatMenuItems(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_MENU}/public/location/${locationId}/published/eat_presto`,
    method: "GET",
  });
}

export async function getCatMenuOptions(locationId) {
  return axiosClient({
    url: `${process.env.REACT_APP_API_ENDPOINT_MENU}/public/location/${locationId}/published/menu-options/eat_presto`,
    method: "GET",
  });
}

/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from "react";

import { Typography } from "@material-ui/core";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import _ from "lodash";
import { connect, useSelector } from "react-redux";
import { bindActionCreators } from "redux";

import { logInfo } from "../../../../../../services/logging";
import FacebookPixel from "../../../../../../utils/analytics/FacebookPixel";
import { gaEventAddToCart } from "../../../../../../utils/googleAnalytics";
import {
  calByoOneUnitPrice,
  postMessageToParentWindow,
  formatPrice,
} from "../../../../../../utils/utils";
import AddToCartBtn from "../../../../../common/AddToCartBtn";
import QuantitySection from "../../../../../common/QuantitySection";
import { addItemToCartAsync } from "../../../MyOrder/redux/actions";
import SubProductStage from "./SubProductStage";
import TicktockPixel from "../../../../../../utils/TicktockPixel";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 2, 2),
    // width: "100%",
  },
  sectionHeading: {},
  divider: {
    margin: theme.spacing(2, 0),
  },
  subitemListing: {
    [theme.breakpoints.up("md")]: {
      // overflow: "auto",
      // maxHeight: 312,
      // ...theme.palette.scrollbarStyles,
    },
  },
}));

const BuildYourItem = ({
  selectedItemData,
  actions,
  handleClose,
  catMenuId,
  isAvailable,
  embedType,
}) => {
  const classes = useStyles();
  const [quantity, setQuantity] = useState(1);
  const [subItems, setSubItems] = useState([]);
  const [errors, setErrors] = useState([]);
  const [expandedStageGroups, setExpandedStageGroups] = useState([]);
  const [subProductData, setSubProductData] = useState([]);

  const { catMenuOptions } = useSelector((state) => state.catMenu);
  const { storeInfo } = useSelector((state) => state.store);
  const {
    storeInfo: { businessDisplayName },
  } = useSelector((state) => state.store);
  const { categorySelected } = useSelector((state) => state.category);
  const { activeCatMenu } = useSelector((state) => state.catMenu);

  const theme = useTheme();

  const initByoState = (subProducts) => {
    let initSubItems = [];
    subProducts.forEach((s, i) => {
      const stage = {
        stage: i,
        title: s.title,
        subs: [],
        mMax: s.mealDealMax,
        mMin: s.mealDealMin,
        pcp: s.mealDealPriceChangePoint,
        stageGroup: s.stageGroup,
      };
      initSubItems = [...initSubItems, stage];
    });
    setSubProductData(subProducts);
    setSubItems(initSubItems);
  };

  useEffect(() => {
    const stageExpandArray = subItems.map((item) =>
      item.subs.map((subItem) => subItem.enableStageGroup),
    );
    const stageSet = new Set(_.compact(stageExpandArray.flat()));
    setExpandedStageGroups([...stageSet]);
  }, [subItems]);

  useEffect(() => {
    if (selectedItemData.data && selectedItemData.data.length) {
      initByoState(selectedItemData.data[0].subProducts);
    }
  }, [selectedItemData]);

  const validate = () => {
    // validate price change
    const _errors = [];
    const stageList = _.cloneDeep(subItems);
    const defaultStageList = [...stageList].filter((stage) =>
      _.isEmpty(stage.stageGroup),
    );
    const applicableStageList = [
      ...[...stageList].filter((stage) =>
        expandedStageGroups.includes(stage.stageGroup),
      ),
      ...defaultStageList,
    ];
    applicableStageList.forEach((s) => {
      if (s.subs.length < s.mMin) {
        _errors.push({
          stage: s.stage,
          error: `Please select at least ${String(s.mMin)} items.`,
        });
      }
      if (s.subs.length > s.mMax) {
        _errors.push({
          stage: s.stage,
          error: `Please select no more than ${String(s.mMax)} items.`,
        });
      }
    });
    setErrors(_errors);
    return _errors;
  };

  const addItemToCart = async () => {
    const _errors = validate();
    if (_errors.length) {
      const elm = document.getElementById(
        `subProductStage-${_errors[0].stage}`,
      );
      return elm.scrollIntoView({ behavior: "smooth" });
    }
    const { price } = selectedItemData.data[0];
    const oneUnitPrice = calByoOneUnitPrice(subItems, price);

    const cartItem = {};
    cartItem.itemData = selectedItemData;
    cartItem.catMenuId = catMenuId;
    cartItem.applicableDiscounts = [];
    [cartItem.item] = selectedItemData.data;
    cartItem.quantity = quantity;
    cartItem.subItems = subItems;
    cartItem.type = "buildYourItem";
    cartItem.totalPrice = (oneUnitPrice * quantity).toFixed(2);
    cartItem.oneUnitPrice = oneUnitPrice.toFixed(2);

    if (!theme?.view?.isMobile && theme?.view?.isFixedList) {
      if (embedType === "WIX") {
        actions.addItemToCartAsync(cartItem);
      } else {
        postMessageToParentWindow({
          addItemToCart: true,
          cartItemNode: cartItem,
        });
      }
    } else {
      actions.addItemToCartAsync(cartItem);
    }
    logInfo({ message: "Item added to cart" });
    handleClose();
    gaEventAddToCart(
      cartItem,
      quantity,
      categorySelected,
      activeCatMenu,
      businessDisplayName,
      oneUnitPrice,
    );
    FacebookPixel.addToCart(cartItem, categorySelected);
    // if (storeInfo?.tikTokAnalyticsId) {
    //   TicktockPixel.addToCart(cartItem, categorySelected);
    // }
  };
  const { price } = selectedItemData.data[0];

  // if (_.isEmpty(subProductData)) return null;

  return (
    <>
      {!!parseFloat(price) && (
        <Typography variant="h6" component="div" style={{ padding: "0 16px" }}>
          {formatPrice(price)}
        </Typography>
      )}
      <div className={classes.root}>
        <div className={classes.subitemListing}>
          {!_.isEmpty(subProductData) &&
            subProductData.map((subProdData, i) => (
              <div id={`subProductStage-${i}`} key={`stage-${String(i)}`}>
                <SubProductStage
                  key={`stage-${String(i)}`}
                  stage={i}
                  subProdData={subProdData}
                  subItems={subItems}
                  setSubItems={setSubItems}
                  expandedStageGroups={expandedStageGroups}
                  errors={errors}
                  catMenuOptions={catMenuOptions}
                />
              </div>
            ))}
        </div>

        <QuantitySection quantity={quantity} setQuantity={setQuantity} />
        <AddToCartBtn onClick={addItemToCart} disabled={!isAvailable} />
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ addItemToCartAsync }, dispatch),
});

export default connect(null, mapDispatchToProps)(BuildYourItem);

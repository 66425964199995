/* eslint-disable react/jsx-props-no-spreading */
import React from "react";

import {
  Grid,
  Hidden,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import Fade from "@material-ui/core/Fade";
import { makeStyles } from "@material-ui/core/styles";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useSelector } from "react-redux";
import ClearIcon from "@material-ui/icons/Clear";
import SearchIcon from "@material-ui/icons/Search";

import IconButtonDefault from "../../../common/buttons/IconButtonDefault";

function a11yProps(index) {
  return {
    id: `scrollable-force-tab-${index}`,
    "aria-controls": `scrollable-force-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  CatMenuTabsWrapper: {
    margin: "6px 6px 0px 0px",
    width: "100%",
    display: "flex",
    justifyContent: "start",
    [theme.breakpoints.down("sm")]: {
      margin: "0px 6px 0px 0px",
    },
  },
  root: {
    width: "100%",
    backgroundColor: theme.palette.background.menuBackgroundColor,
  },
  roots: {
    backgroundColor: theme.palette.background.menuBackgroundColor,
    
    "& .MuiTabs-root": {
      backgroundColor: theme.palette.background.menuBackgroundColor,
      [theme.breakpoints.down("sm")]: {
        maxWidth: "calc(100vw - 280px)",
      },
    },
  },
  rootCenterTabs: {
    "& .MuiTabs-root": {
      backgroundColor: theme.palette.background.menuBackgroundColor,
    },
    "& .MuiTabs-flexContainer": {
      justifyContent: "center",
    },
  },
  tabRoot: {
    "&.MuiTab-root": {
      color: theme.palette.text.itemTextColor,
    },
    "&.Mui-selected": {
      color: theme.palette.text.titleTextColor,
    },
  },
  arrowIcon: {
    color: theme.palette.text.titleTextColor,
    width: 30,
  },
  arrow: {
    width: 22,
  },
  iconStyle: {
    color: theme.palette.text.itemTextColor,
  },
  textField: {
    overflowWrap: "break-word",
    wordWrap: "break-word",
    borderRadius: "10px",
    border: `1px solid ${theme.palette.text.itemTextColor}`,
    color: theme.palette.text.itemTextColor,
    [`& fieldset`]: {
      borderRadius: "10px",
      color: theme.palette.text.itemTextColor,
      border: `1px solid ${theme.palette.text.itemTextColor}`,
      cursor: "pointer",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text.itemTextColor,
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      color: theme.palette.text.itemTextColor,
      border: `1px solid ${theme.palette.text.itemTextColor}`,
    },
    "& .Mui-focused .MuiOutlinedInput-notchedOutline": {
      color: theme.palette.text.itemTextColor,
      border: `1px solid ${theme.palette.text.itemTextColor}`,
    },
    // color: theme.palette.primary.contrastText,
    // "& .MuiInputBase-root": {
    //   color: theme.palette.primary.contrastText,
    //   borderRadius: theme.shape.borderRadius,
    // },
    // "& .MuiFormLabel-root": {
    //   color: theme.palette.primary.contrastText,
    // },
  },
  searchGrid: {
    paddingLeft: "18px",
    paddingRight: "28px",
  },
}));

const CatMenuTabs = ({
  handleChange,
  activeIdx,
  searchName,
  handleChangeSearch,
  setSearchName,
  setIsOpen,
  isOpen,
}) => {
  const classes = useStyles();
  // const theme = useTheme();

  // const matches = useMediaQuery(theme.breakpoints.down("md"));
  const { catMenuList } = useSelector((state) => state.catMenu);
  const handleClearButton = () => {
    if (searchName || isOpen) {
      setIsOpen(false);
      setSearchName("");
    }
  };

  const handleOpenSearch = () => {
    setIsOpen(true);
  };

  const theme = useTheme();
  return (
    <div className={classes.CatMenuTabsWrapper}>
      <div className={classes.root}>
        <Hidden smDown>
          <Grid container style={{ placeItems: "center" }}>
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                alignItems: "Center",
                justifyContent: "space-between",
              }}
            >
              <div
                style={
                  isOpen
                    ? {
                        width: "calc(100% - 245px)",
                        display: "flex",
                        justifyContent: "start",
                      }
                    : {
                        width: "calc(100% - 60px)",
                        display: "flex",
                        justifyContent: "start",
                      }
                }
              >
                <Tabs
                  classes={{
                    root:
                      catMenuList.length === 1
                        ? classes.rootCenterTabs
                        : classes.roots,
                  }}
                  value={activeIdx}
                  onChange={handleChange}
                  variant="scrollable"
                  selectionFollowsFocus
                  scrollButtons="on"
                  indicatorColor="primary"
                  textColor="primary"
                  aria-label="scrollable category menu items list"
                  ScrollButtonComponent={(props) =>
                    props.direction === "left" ? (
                      <Fade in={!props.disabled}>
                        <IconButtonDefault
                          className={classes.arrow}
                          aria-label="ArrowBackIosIcon"
                          {...props}
                        >
                          <ChevronLeftIcon
                            className={classes.arrowIcon}
                            fontSize="large"
                          />
                        </IconButtonDefault>
                      </Fade>
                    ) : (
                      <Fade in={!props.disabled}>
                        <IconButtonDefault
                          className={classes.arrow}
                          aria-label="ArrowForwardIosIcon"
                          {...props}
                        >
                          <ChevronRightIcon
                            className={classes.arrowIcon}
                            fontSize="large"
                          />
                        </IconButtonDefault>
                      </Fade>
                    )
                  }
                >
                  {catMenuList.map((catMenu, idx) => (
                    <Tab
                      key={String(idx)}
                      classes={{ root: classes.tabRoot }}
                      label={
                        <Typography style={{ fontSize: "14px" }}>
                          {catMenu.title}
                        </Typography>
                      }
                      {...a11yProps(idx)}
                    />
                  ))}
                </Tabs>
              </div>
              {isOpen ? (
                <TextField
                  classes={{ root: classes.textField }}
                  id="outlined-inline-static"
                  placeholder="Search for a dish"
                  variant="outlined"
                  onChange={handleChangeSearch}
                  value={searchName}
                  fullWidth
                  style={{
                    width: "200px",
                    marginLeft: "16px",
                    marginRight: "26px",
                  }}
                  size="small"
                  InputProps={{
                    maxLength: 200,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          edge="end"
                          style={{ color: theme.palette.text.itemTextColor }}
                          onClick={handleClearButton}
                        >
                          <ClearIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) : (
                <SearchIcon
                  style={{ cursor: "pointer", marginRight: "26px" }}
                  onClick={handleOpenSearch}
                />
              )}
            </Grid>
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={8}
              lg={9}
              className={isOpen && classes.grid}
            >
              <Grid container>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  style={{
                    minHeight: 56,
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <Tabs
                    classes={{
                      root:
                        catMenuList.length === 1
                          ? classes.rootCenterTabs
                          : classes.root,
                    }}
                    value={activeIdx}
                    onChange={handleChange}
                    variant="scrollable"
                    selectionFollowsFocus
                    scrollButtons="on"
                    indicatorColor="primary"
                    textColor="primary"
                    aria-label="scrollable category menu items list"
                    ScrollButtonComponent={(props) =>
                      props.direction === "left" ? (
                        <Fade in={!props.disabled}>
                          <IconButtonDefault
                            className={classes.arrow}
                            aria-label="ArrowBackIosIcon"
                            {...props}
                          >
                            <ChevronLeftIcon
                              className={classes.arrowIcon}
                              fontSize="large"
                            />
                          </IconButtonDefault>
                        </Fade>
                      ) : (
                        <Fade in={!props.disabled}>
                          <IconButtonDefault
                            className={classes.arrow}
                            aria-label="ArrowForwardIosIcon"
                            {...props}
                          >
                            <ChevronRightIcon
                              className={classes.arrowIcon}
                              fontSize="large"
                            />
                          </IconButtonDefault>
                        </Fade>
                      )
                    }
                  >
                    {catMenuList.map((catMenu, idx) => (
                      <Tab
                        key={String(idx)}
                        classes={{ root: classes.tabRoot }}
                        label={
                          <Typography style={{ fontSize: "14px" }}>
                            {catMenu.title}
                          </Typography>
                        }
                        {...a11yProps(idx)}
                      />
                    ))}
                  </Tabs>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={0}
              sm={0}
              md={4}
              lg={3}
              className={classes.searchGrid}
            >
              <TextField
                classes={{ root: classes.textField }}
                id="outlined-inline-static"
                placeholder="Search for a dish"
                variant="outlined"
                onChange={handleChangeSearch}
                value={searchName}
                fullWidth
                size="small"
                InputProps={{
                  maxLength: 200,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        edge="end"
                        style={{ color: theme.palette.text.itemTextColor }}
                        onClick={handleClearButton}
                      >
                        {searchName ? <ClearIcon /> : <SearchIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid> */}
          </Grid>
        </Hidden>

        <Hidden mdUp>
          <Grid container style={{ placeItems: "center" }}>
            <Grid
              item
              xs={12}
              style={{
                minHeight: 56,
                display: "flex",
                alignItems: "center",
                justifyContent: "Start",
              }}
            >
              <Tabs
                classes={{
                  root:
                    catMenuList.length === 1
                      ? classes.rootCenterTabs
                      : classes.roots,
                }}
                value={activeIdx}
                onChange={handleChange}
                variant="scrollable"
                selectionFollowsFocus
                scrollButtons="on"
                indicatorColor="primary"
                textColor="primary"
                aria-label="scrollable category menu items list"
                ScrollButtonComponent={(props) =>
                  props.direction === "left" ? (
                    <Fade in={!props.disabled}>
                      <IconButtonDefault
                        className={classes.arrow}
                        aria-label="ArrowBackIosIcon"
                        {...props}
                      >
                        <ChevronLeftIcon
                          className={classes.arrowIcon}
                          fontSize="large"
                        />
                      </IconButtonDefault>
                    </Fade>
                  ) : (
                    <Fade in={!props.disabled}>
                      <IconButtonDefault
                        className={classes.arrow}
                        aria-label="ArrowForwardIosIcon"
                        {...props}
                      >
                        <ChevronRightIcon
                          className={classes.arrowIcon}
                          fontSize="large"
                        />
                      </IconButtonDefault>
                    </Fade>
                  )
                }
              >
                {catMenuList.map((catMenu, idx) => (
                  <Tab
                    key={String(idx)}
                    classes={{ root: classes.tabRoot }}
                    label={
                      <Typography style={{ fontSize: "14px" }}>
                        {catMenu.title}
                      </Typography>
                    }
                    {...a11yProps(idx)}
                  />
                ))}
              </Tabs>
            </Grid>
          </Grid>
        </Hidden>
      </div>
    </div>
  );
};

CatMenuTabs.propTypes = {};
CatMenuTabs.defaultProps = {};

export default CatMenuTabs;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import {
  Button,
  Checkbox,
  Grid,
  Hidden,
  Typography,
  useTheme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import _ from "lodash";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import StarIcon from "@material-ui/icons/Star";

import LoginDialog from "../../components/@pages/storeHome/LoginDialog";
import AlertMessage from "../../components/common/AlertMessage";
import Layout from "../../components/Layout";
import withInitDataFetch from "../../components/Layout/withFetch";
import { setIsOpenLoginModal } from "../../redux/actions/auth";
import { checkOrderPublic } from "../../services/orderService";
import RestaurantContactInfo from "./RestaurantContactInfo";
import { getLocationInfo } from "../../services/locationService";
import SecondaryThemeProvider from "../../MyOrderPageContainers/SecondaryThemeProvider";
import ContentSection from "../../MyOrderPageContainers/ContentSection";
import ContentContainer from "../../MyOrderPageContainers/ContentContainer";
import PageWrapper from "../../MyOrderPageContainers/PageWrapper";
import CartItemCard from "../../components/@pages/NewOrderStatus/CartItemCard";
import OrderStepper from "../../components/@pages/NewOrderStatus/OrderStepper";
import PageHeading from "../../components/@pages/NewOrderStatus/PageHeading";
import PriceBreakdown from "../../components/@pages/NewOrderStatus/PriceBeakdown";
import Color from "color";
import SkeletonLoading from "./SkeletonLoading";
import ReOrder from "./ReOrder";
import { getCatMenuItems, getCatMenuOptions } from "../../services/menuService";
import { logError, logInfo } from "../../services/logging";
import {
  FETCH_CAT_MENU_LIST_ERROR,
  FETCH_CAT_MENU_OPTIONS_ERROR,
} from "../../components/@pages/storeHome/CatMenu/redux/actions";
import i18next from "i18next";
import {
  addItemToCartAsync,
  setCartItems,
} from "../../components/@pages/storeHome/MyOrder/redux/actions";
import { bindActionCreators } from "redux";
import {
  calByoOneUnitPrice,
  getPriceByPcp,
  postMessageToParentWindow,
} from "../../utils/utils";
import { gaEventAddToCart } from "../../utils/googleAnalytics";
import FacebookPixel from "../../utils/analytics/FacebookPixel";
import { removeLocalStore } from "../../utils/storage/localStorage";
import DialogDefault from "../../components/common/dialogs/DialogDefault";
import { getAvailabilityOfItem } from "../../utils/availability";
import TicktockPixel from "../../utils/TicktockPixel";

/**
 * This function takes an orderStatus as input and returns a corresponding color code.
 */
const getStatusColor = (orderStatus) => {
  // Check if the orderStatus is "new" or "pos"
  if (orderStatus === "new" || orderStatus === "pos") {
    // If it is, return the color code "#258AC2"
    return "#258AC2";
  }
  // Check if the orderStatus is "accepted"
  if (orderStatus === "accepted") {
    // If it is, return the color code "#CDA545"
    return "#CDA545";
  }
  // Check if the orderStatus is "ready"
  if (orderStatus === "ready") {
    // If it is, return the color code "#88A548"
    return "#88A548";
  }
  // If none of the above conditions are met, return the default color code "#258AC2"
  return "#258AC2";
};

const useStyles = makeStyles((theme) => ({
  cardWrapperSelectedOrderDetailsMobile: {
    padding: "8px",
    color: theme.palette.text.itemTextColor,
  },
  CartItemCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginBottom: "4px",
    minHeight: 48,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: Color(
      theme.palette.background.menuBackgroundColor,
    ).isDark()
      ? Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#fff"), 0.1)
          .hex()
      : Color(theme.palette.background.menuBackgroundColor)
          .mix(Color("#000"), 0.1)
          .hex(),
    padding: theme.spacing(1),
  },
  cardWrapperSelectedOrderDetailsDesktop: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "70vh",
    padding: "8px",
    color: theme.palette.text.itemTextColor,
    [theme.breakpoints.down("md")]: {
      height: "85vh",
    },
  },
  itemListing: {
    overflow: "auto",
    [theme.breakpoints.down("lg")]: {
      maxHeight: `calc(100vh - ${108}px)`,
      marginBottom: "12px",
    },
    ...theme.palette.scrollbarStyles,
    "&::-webkit-scrollbar": {
      width: 0,
    },
  },
  headingStyle: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "space-between",
    [theme.breakpoints.down("md")]: {
      marginBottom: "0px",
    },
  },
}));

const WithDataLayout = withInitDataFetch(Layout);

const NewOrderStatusPage = ({ actions }) => {
  const [orderStatus, setOrderStatus] = useState("new");
  const [alertMessage, setAlertMessage] = useState("");
  const [orderNumber, setOrderNumber] = useState("");
  const [orderData, setOrderData] = useState({});
  const [orderClient, setOrderClient] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [businessDisplayName, setBusinessDisplayName] = useState("");
  const [contactNo, setContactNo] = useState("");
  const [addressFormatted, setAddressFormatted] = useState("");
  const [open, setOpen] = useState("");
  const [error, setError] = useState("");
  const [idList, setIdList] = useState([]);
  const [isLoadingButton, setIsLoadingButton] = useState(false);
  const [initialIdLists, setInitialIdLists] = useState({});
  const [validateId, setValidateId] = useState("");
  const windowHeight = window.innerHeight;
  const { categorySelected } = useSelector((state) => state.category);
  const { activeCatMenu } = useSelector((state) => state.catMenu);

  const classes = useStyles({ windowHeight });
  const { params } = useRouteMatch();
  const history = useHistory();
  const dispatch = useDispatch();
  const location = useLocation();
  const theme = useTheme();

  const { isAuthenticated } = useSelector((state) => state.auth);
  const { embedType } = useSelector((state) => state.global);
  const { storeInfo } = useSelector((state) => state.store);
  useEffect(() => {
    getLocationInformation();
  }, []);

  /**
   * Fetches location information using the provided locationId and sets the retrieved data in state variables.
   */
  const getLocationInformation = async () => {
    try {
      // Extract the locationId from the params object
      const { locationId } = params;
      // Fetch the location information using the locationId
      const res = await getLocationInfo(locationId);
      // Destructure the businessDisplayName property from the fetched data
      const { businessDisplayName, contactNo, addressFormatted } =
        res.data.data;
      setBusinessDisplayName(businessDisplayName);
      setAddressFormatted(addressFormatted);
      setContactNo(contactNo);
    } catch (err) {
      // Set the alert message to display an unexpected error if an error occurs
      setAlertMessage("Error fetching location details.");
    }
  };

  /**
   * Registers a listener on the window's `popstate` event and updates the browser's URL when the event occurs.
   * This is used to redirect the user to a specific location shop page when the browser's back button is pressed.
   */
  useEffect(() => {
    // Define the callback function to be executed when the `popstate` event occurs
    const handlePopState = () => {
      // Construct the new URL using the `params.locationId` and `location.search` values
      const newUrl = {
        pathname: `/location/${params.locationId}/shop`,
        search: location.search,
      };

      // Update the browser's URL to the new URL constructed above
      history.push(newUrl);
    };

    // Register the `handlePopState` function as the listener for the `popstate` event
    window.onpopstate = handlePopState;

    // Clean up the effect by unregistering the listener when the component unmounts or when the dependencies change
    return () => {
      window.onpopstate = null;
    };
  }, []);

  /**
   * Cleans up any resources when the component unmounts or when the `orderClient` dependency changes.
   * If `orderClient` is defined, it unsubscribes from any subscriptions.
   */
  useEffect(() => {
    // Define the cleanup function to be executed when the component unmounts or when the `orderClient` dependency changes
    const cleanup = () => {
      // Check if `orderClient` is defined
      if (orderClient) {
        // If `orderClient` is defined, unsubscribe from any subscriptions
        orderClient.unsubscribe();
      }
    };

    // Execute the cleanup function when the component unmounts or when the `orderClient` dependency changes
    return cleanup;
  }, [orderClient]);

  /**
   * Subscribes to a real-time connection using Ably and sets up event handlers to update order status.
   * The function creates a client using the provided Ably API key and subscribes to a channel based on the orderId.
   * It sets the order client and listens for status updates to update the order status state variable.
   */
  const subscribeConnection = () => {
    // Create a new Ably Realtime client using the provided API key
    const client = new window.Ably.Realtime(
      process.env.REACT_APP_ABLY_PUBSUB_API_KEY,
    );

    // Handle the "connected" event when the client's connection is established
    client.connection.on("connected", () => {
      // Extract the orderId from the params object
      const { orderId } = params;
      // Get the channel based on the orderId
      const channel = client.channels.get(`order:${orderId}`);
      setOrderClient(channel);

      // Subscribe to the channel and listen for incoming messages
      channel.subscribe((message) => {
        try {
          // Parse the message data to extract the status
          const { status } = JSON.parse(message.data);
          // Update the order status state variable
          setOrderStatus(status);

          // Set isLoading to false since the order status has been updated
          setIsLoading(false);
        } catch (error) {
          // If an error occurs while parsing the message data, set an error alert message
          setAlertMessage("Error fetching order status. ");
          // Set isLoading to false to indicate that the order status fetch has completed (even if it failed)
          setIsLoading(false);
        }
      });
    });

    // Handle the "failed" event when the client's connection fails
    client.connection.on("failed", () => {
      // Set an alert message indicating the connection error
      setAlertMessage(
        "Error connecting to server. Please try reloading the page",
      );
    });
  };

  /**
   * Fetches the order status and details, sets the necessary state variables, and subscribes to the real-time connection.
   * The function makes an asynchronous call to fetch the order status and details using the provided `orderId` and `locationId`.
   * It updates the order number, order data, and sets the isLoading state variable.
   * If an error occurs during the fetching process, it sets an appropriate alert message and handles specific error cases.
   * The function is executed when the isAuthenticated dependency changes.
   */
  useEffect(() => {
    // Define the fetchOrderStatus function, which fetches the order status and details
    const fetchOrderStatus = async () => {
      try {
        // Extract the orderId and locationId from the params object
        const { orderId, locationId } = params;
        // Make an asynchronous call to check the order status using the locationId and orderId
        const res = await checkOrderPublic(locationId, orderId);
        // Destructure the displayRef and order data from the response
        const { displayRef } = res.data.data;
        // Set the order number and order data in the state variable
        setOrderNumber(displayRef);

        setOrderData(res.data.data);

        setIsLoading(false);

        // Subscribe to the real-time connection for order updates
        subscribeConnection();
      } catch (error) {
        // Handle specific error cases based on the response status
        if (error?.response?.status === 400) {
          setAlertMessage("Order has been expired.");
        }
        if (error?.response?.status === 401) {
          dispatch(setIsOpenLoginModal(true));
        }
        // Set isLoading to false to indicate that the order fetch has completed (even if it failed)
        setIsLoading(false);
        // Set an alert message indicating the error fetching order details
        return setAlertMessage("Error fetching order details.");
      }
    };

    fetchOrderStatus();
  }, [isAuthenticated]);

  const {
    customer,
    deliveryAddress,
    deliveryCost,
    deliveryIsAsap,
    deliveryType,
    discountTotal,
    dueTime,
    items: cartItems,
    paymentAmount,
    serviceCharge,
    extraCharges,
  } = orderData;

  const { items: cartItemsClone } = orderData;

  /**
   * Redirects the user to the home page of their orders for the specific location.
   * The function uses the `history` object to navigate to the home page of the user's orders.
   * The pathname is constructed based on the `params.locationId` and the `/my-orders` route.
   * The search parameter is preserved from the current location.
   */
  const redirectToHome = () => {
    // Use the `history` object to navigate to the home page of the user's orders
    history.push({
      // Construct the pathname based on the `params.locationId` and the `/my-orders` route
      pathname: `/location/${params.locationId}/my-orders`,
      // Preserve the search parameter from the current location
      search: location.search,
    });
  };

  /**
   * Fetches the menu information for a specific location.
   * The function makes an asynchronous call to fetch the menu items data using the provided `locationId`.
   * It returns the fetched menu data.
   * If an error occurs during the fetching process, it logs the error and dispatches an action with an error message.
   */
  const getMenuInformation = async () => {
    try {
      // Extract the locationId from the params object
      const { locationId } = params;
      // Make an asynchronous call to fetch the menu items data using the locationId
      const res = await getCatMenuItems(locationId);
      // Return the fetched menu data
      return res.data.data;
    } catch (error) {
      logError({ message: "Error fetching menu items data", error });

      // Dispatch an action with an error message indicating the network failure
      return dispatch(
        FETCH_CAT_MENU_LIST_ERROR(i18next.t("errors.networkFailed")),
      );
    }
  };

  /**
   * Clears the cart by removing the orderId from local storage and setting the cart items to an empty array.
   * The function removes the "orderId" key from local storage using the `removeLocalStore` function.
   * It also dispatches an action to set the cart items to an empty array using the `setCartItems` action creator.
   */
  const handleClearCart = () => {
    // Remove the "orderId" key from local storage
    removeLocalStore("orderId");

    // Dispatch an action to set the cart items to an empty array
    dispatch(setCartItems([]));
  };

  /**
   * This function handles the selection of sub-items based on provided subItems, subProducts, and initial subItems.
   * It iterates through the subProducts and matches the subItemIds to determine the selected sub-items.
   * The function updates the selectedSubItems array by adding the selected sub-items from subProducts.
   * It also calculates and updates the price of each selected sub-item.
   * The modified selectedSubItems array is then returned.
   *
   * @param {Array} subItems - An array of sub-items to be selected.
   * @param {Array} subProducts - An array of sub-products containing available sub-items.
   * @param {Array} initSubItems - An array of initially selected sub-items.
   * @returns {Array} - The modified array of selected sub-items.
   */
  const handleSelectSubItem = (subItems, subProducts, initSubItems, index1) => {
    // Create a copy of initial selected sub-items
    const selectedSubItems = [...initSubItems];
    // Extract sub-item IDs from subItems array
    const subItemIds = subItems.map((item) => item.id);

    // Create a map to store sub-products indexed by their item IDs
    const subProductsMap = {};

    // Get the length of subItemIds array
    const subItemIdsLen = subItemIds.length;

    // Initialize a counter for new sub-item IDs
    let count = 0;

    // Build the subProductsMap
    subProducts.forEach((stage1, i) => {
      // Create a map for each stage1
      let subProductsMapByItemId = {};

      // Check if stage1 has subProducts
      if (!_.isEmpty(stage1["subProducts"])) {
        // Iterate through subProducts of stage1
        stage1["subProducts"].forEach((stage2) => {
          // Map each subProduct to its itemId
          subProductsMapByItemId[stage2.itemId] = stage2;
        });
      }
      // Store the subProductsMapByItemId in the subProductsMap using the index i
      subProductsMap[i] = subProductsMapByItemId;
    });
    let allId = {};
    subItems.forEach((data) => {
      let breakLoop = true;
      subProducts.forEach((stage1, i) => {
        if (stage1.subProducts) {
          const newIdList = [];
          stage1["subProducts"].forEach((stage2) => {
            if (data.stageId) {
              const updatedSubProduct = {
                ...stage2,
                price: getPriceByPcp(
                  initSubItems[i]["subs"],
                  stage1.mealDealPriceChangePoint,
                  stage2.extraCost,
                  stage2.surcharge,
                  stage2.itemId,
                ),
              };

              // Check if subItems' IDs include sub.itemId
              if (
                idList.includes(stage2.itemId) &&
                stage1.itemId === data.stageId &&
                data.id === stage2.itemId &&
                !newIdList.includes(stage2.itemId) &&
                breakLoop
              ) {
                // Create a new sub-item object with assigned price
                if (index1 === 3) {
                }

                // Check whether the stage can have only one selection
                if (stage1.mealDealMax === 1) {
                  if (selectedSubItems[i].subs.length === 0) {
                    selectedSubItems[i].subs = [updatedSubProduct];
                  }
                } else {
                  // add new sub item to main prod array
                  selectedSubItems[i].subs.push(updatedSubProduct);
                }
                newIdList.push(stage2.itemId);
                breakLoop = false;
              }
            } else {
              if (
                data.id === stage2.itemId &&
                subItemIds.includes(stage2.itemId) &&
                !newIdList.includes(stage2.itemId) &&
                breakLoop
              ) {
                if (count < subItemIdsLen) {
                  if (
                    (stage1.mealDealMax > selectedSubItems[i].subs.length ||
                      _.isEmpty(selectedSubItems[i])) &&
                    (_.isEmpty([stage1.itemId]["itemId"]) ||
                      !allId[stage1.itemId]["itemId"].includes(stage2.itemId))
                  ) {
                    count = count + 1;
                    const updatedSubProduct = {
                      ...stage2,
                      price: getPriceByPcp(
                        initSubItems[i]["subs"],
                        stage1.mealDealPriceChangePoint,
                        stage2.extraCost,
                        stage2.surcharge,
                        stage2.itemId,
                      ),
                    };
                    newIdList.push(stage2.itemId);
                    const id = stage2.itemId;
                    const updatedAllId = { ...allId };
                    const nestedObj = updatedAllId[stage1.itemId];
                    const updatedNestedObj = { ...nestedObj };
                    if (!updatedNestedObj["itemId"]) {
                      updatedNestedObj["itemId"] = [];
                    }
                    updatedNestedObj["itemId"].push(id);
                    updatedAllId[stage1.itemId] = updatedNestedObj;
                    allId = updatedAllId;

                    selectedSubItems[i].subs.push(updatedSubProduct);
                    breakLoop = false;
                  }
                }
              }
            }
          });
        }
      });
    });
    return selectedSubItems;
  };

  /**
   * Initializes the BYO (Build Your Own) state based on the provided subProducts.
   *
   * @param {Array} subProducts - Array of subProducts
   * @returns {Object} - Initialized BYO state object containing subProducts and initSubItems
   */
  const initByoState = (subProducts, id) => {
    let initSubItems = [];
    // Iterate through subProducts to create the initial subItems array
    subProducts.forEach((subProduct, i) => {
      const stage = {
        stage: i,
        title: subProduct.title,
        subs: [],
        mMax: subProduct.mealDealMax,
        mMin: subProduct.mealDealMin,
        pcp: subProduct.mealDealPriceChangePoint,
        stageGroup: subProduct.stageGroup,
      };
      // Add the stage to the initSubItems array
      initSubItems = [...initSubItems, stage];
    });

    return { subProducts, initSubItems };
  };

  /**
   * Validates the selected sub-items based on the provided parameters.
   *
   * @param {Array} updatedSubItems - Updated sub-items array
   * @param {Array} expandedStageGroups - Array of expanded stage groups
   * @param {number} index - Index value
   * @returns {Array} - Array of validation errors
   */
  // const validate = (updatedSubItems, expandedStageGroups, index) => {
  //   const _errors = [];
  //   // Create a deep clone of the updatedSubItems array
  //   const stageList = _.cloneDeep(updatedSubItems);

  //   // Filter stages without a stageGroup
  //   const defaultStageList = [...stageList].filter((stage) =>
  //     _.isEmpty(stage.stageGroup),
  //   );

  //   // Create applicable stage list by combining stages from expanded stage groups and default stages
  //   const applicableStageList = [
  //     ...[...stageList].filter((stage) =>
  //       expandedStageGroups.includes(stage.stageGroup),
  //     ),
  //     ...defaultStageList,
  //   ];

  //   // Iterate through applicable stages and perform validation checks
  //   applicableStageList.forEach((stage) => {
  //     // Check if the number of selected sub-items is less than the minimum required
  //     if (stage.subs.length < stage.mMin) {
  //       // Push an error object to the errors array
  //       _errors.push({
  //         stage: stage.stage,
  //         error: `Please select at least ${String(stage.mMin)} items.`,
  //         index: index,
  //       });
  //     }

  //     // Check if the number of selected sub-items exceeds the maximum allowed
  //     if (stage.subs.length > stage.mMax) {
  //       // Push an error object to the errors array
  //       _errors.push({
  //         stage: stage.stage,
  //         error: `Please select no more than ${String(stage.mMax)} items.`,
  //         index: index,
  //       });
  //     }
  //   });

  //   // Return the array of validation errors
  //   return _errors;
  // };

  /**
   * Adds items to the basket based on the provided cartItem and menu.
   *
   * @param {Array} cartItem - Array of items to be added to the cart
   * @param {Object} menu - Menu object containing item data
   */
  const addItemToCart = (cartItem, menu) => {
    // Iterate through each cart item
    cartItem.forEach((item, index) => {
      const { catMenuId, catId, menuId, quantity, price, subItems } = item;

      // Get the selected item from the menu
      const selectedItem =
        menu[`CM${catMenuId}`]["data"][`C${catId}`]["data"][`M${menuId}`];

      const cartItm = {};
      cartItm.itemData = selectedItem;
      cartItm.catMenuId = catMenuId;
      cartItm.applicableDiscounts = [];
      cartItm.quantity = quantity;

      if (!_.isEmpty(subItems)) {
        // If the item has sub-items, it is a build-your-own item
        cartItm.type = "buildYourItem";
        const subProducts = selectedItem.data[0]["subProducts"];
        // Initialize the subItems and handle their selection
        const { initSubItems } = initByoState(subProducts, selectedItem);

        const updatedSubItems = handleSelectSubItem(
          subItems,
          subProducts,
          initSubItems,
          index,
        );
        // Expand the relevant stage groups for validation
        // const stageExpandArray = updatedSubItems.map((item) =>
        //   item.subs.map((subItem) => subItem.enableStageGroup),
        // );

        // Create a new Set to store unique stage groups from the expanded subItems
        // const stageSet = new Set(_.compact(stageExpandArray.flat()));

        // Validate the updatedSubItems using the stageSet
        // const _errors = validate(updatedSubItems, [...stageSet], index);

        // Check if there are any errors returned from the validation
        //   if (_errors.length) {
        //     // If there are errors, set the errors using the setErrors function (assuming it's available)

        //     return setErrors(_errors);
        //   }

        // Calculate the price for one unit of the build-your-own item
        const oneUnitPrice = calByoOneUnitPrice(updatedSubItems, price);
        [cartItm.item] = selectedItem.data;
        cartItm.subItems = updatedSubItems;
        cartItm.totalPrice = (oneUnitPrice * quantity).toFixed(2);
        cartItm.oneUnitPrice = parseFloat(oneUnitPrice).toFixed(2);
      } else if (selectedItem.data.length > 1) {
        // If the item has multiple options, it is a multiDirect item
        [cartItm.item] = selectedItem.data.filter(
          (data1) => data1.itemId === item.id,
        );
        cartItm.type = "multiDirect";
        cartItm.totalPrice = (price * quantity).toFixed(2);
        cartItm.oneUnitPrice = parseFloat(price).toFixed(2);
      } else {
        // If the item has a single option, it is a singleDirect item
        [cartItm.item] = selectedItem.data;
        cartItm.type = "singleDirect";
        cartItm.totalPrice = (price * quantity).toFixed(2);
        cartItm.oneUnitPrice = parseFloat(price).toFixed(2);
      }
      // Add the item to the cart based on the conditions
      if (!theme?.view?.isMobile && theme?.view?.isFixedList) {
        // Check if the view is not mobile and is a fixed list
        // If the embedType is "WIX", add the item to the cart asynchronously using actions
        if (embedType === "WIX") {
          actions.addItemToCartAsync(cartItm);
        } else {
          // If the embedType is not "WIX", post a message to the parent window to add the item to the cart
          postMessageToParentWindow({
            addItemToCart: true,
            cartItemNode: cartItm,
          });
        }
      } else {
        // If the view is mobile or not a fixed list, add the item to the cart asynchronously using actions
        actions.addItemToCartAsync(cartItm);
      }
      logInfo({ message: "Item added to cart"
      // , cartItm
       });

      // Track the event in Google Analytics
      gaEventAddToCart(
        cartItm,
        quantity,
        categorySelected,
        activeCatMenu,
        businessDisplayName,
        price,
      );
      // Track the event in Facebook Pixel
      FacebookPixel.addToCart(cartItm, categorySelected);
      // if (storeInfo?.tikTokAnalyticsId) {
      //   TicktockPixel.addToCart(cartItm, categorySelected);
      // }
    });

    // Handle the necessary actions after adding items to the cart
    handleBackward();
  };

  /**
   * Handles the backward navigation action by setting the isLoadingButton state to false and
   * redirecting to a menu page and search parameters using the history object.
   */
  const handleBackward = () => {
    setIsLoadingButton(false);
    // Redirect to the menu page and search parameters using the history object
    history.push({
      pathname: `/location/${params.locationId}/shop`,
      search: location.search,
    });
  };

  /**
   * Retrieves the category menu options asynchronously, based on the specified location ID.
   */
  const getCatMenuOption = async () => {
    try {
      // Retrieve the location ID from the params object
      const { locationId } = params;
      // Fetch the category menu options using the getCatMenuOptions function
      const res = await getCatMenuOptions(locationId);

      // Return the options data from the response
      return res.data.data.options;
    } catch (error) {
      // Log the error and dispatch a FETCH_CAT_MENU_OPTIONS_ERROR action
      logError({ message: "Error fetching menu options data", error });
      return dispatch(
        FETCH_CAT_MENU_OPTIONS_ERROR(i18next.t("errors.networkFailed")),
      );
    }
  };

  const handleGetOrderItemAndSubItemList = () => {
    const itemIdList = [];
    const subItemIdObj = {};
    const subItemIdList = [];

    // Iterate through each cart item
    cartItems.forEach((item, index) => {
      // Add the main item ID to the item ID list
      itemIdList.push(`item${item.id}/${index}`);
      if (_.isEmpty(subItemIdObj[`item${item.id}/${index}`])) {
        subItemIdObj[`item${item.id}/${index}`] = { id: [] };
      }

      // Check if the item has sub-items
      if (item.subItems) {
        // Iterate through each sub-item and add its ID to the sub-item ID list
        item.subItems.forEach((subItem) => {
          const { id } = subItem;
          subItemIdList.push(id);

          subItemIdObj[`item${item.id}/${index}`].id.push(id);
        });
      }
    });
    return { itemIdList, subItemIdList, subItemIdObj };
  };

  const handleValidateId = (newIdObj) => {
    let validateId = {};
    cartItemsClone.forEach((data, index) => {
      if (!validateId[`item${data.id}/${index}`]) {
        validateId[`item${data.id}/${index}`] = { validate: true };
      }

      if (data.subItems) {
        data.subItems.forEach((data1) => {
          if (
            newIdObj[`item${data.id}/${index}`] &&
            newIdObj[`item${data.id}/${index}`].id.includes(data1.id)
          ) {
          } else {
            validateId[`item${data.id}/${index}`] = { validate: false };
          }
        });
      } else {
        if (validateId[`item${data.id}/${index}`]) {
        } else {
          validateId[`item${data.id}/${index}`] = { validate: false };
        }
      }
    });

    return validateId;
  };

  /**
   * Handles the reordering process by fetching menu information and category menu options,
   * clearing the cart, and updating the ID lists for reordering.
   */
  const handleReOrder = async () => {
    try {
      // Fetch menu information and category menu options asynchronously
      const menu = await getMenuInformation();
      const menuOption = await getCatMenuOption();

      // Clear the cart
      handleClearCart();

      // Initialize ID lists
      const newIdList = [];
      const newIdObj = {};

      const { itemIdList, subItemIdList, subItemIdObj } =
        handleGetOrderItemAndSubItemList();

      // Iterate through each cart item again
      cartItems.forEach((item, index) => {
        const { catMenuId, catId, menuId, subItems, id } = item;
        const selectedItem =
          menu[`CM${catMenuId}`]["data"][`C${catId}`]["data"][`M${menuId}`];
        const { isItemActive } = getAvailabilityOfItem(
          menuId,
          catId,
          catMenuId,
          menuOption,
          "",
          "",
        );

        // Check if the selected item exists and is active
        if (selectedItem) {
          if (
            itemIdList.includes(`item${id}/${index}`) &&
            selectedItem.isActive &&
            isItemActive
          ) {
            // Iterate through each data item in the selected item
            selectedItem.data.forEach((dataItem) => {
              if (
                itemIdList.includes(`item${dataItem.itemId}/${index}`) &&
                dataItem.isActive === true &&
                !newIdList.includes(`item${dataItem.itemId}/${index}`)
              ) {
                // Add the main item ID to the new ID list
                newIdList.push(`item${id}/${index}`);
                if (_.isEmpty(newIdObj[`item${id}/${index}`])) {
                  newIdObj[`item${id}/${index}`] = { id: [] };
                }
              }
            });
          }

          if (subItems && isItemActive) {
            const selectedSubProducts = selectedItem["data"][0]["subProducts"];
            // Check if the selected item has sub-products
            if (selectedSubProducts) {
              selectedSubProducts.forEach((subProduct) => {
                if (
                  subItemIdList.includes(subProduct.itemId) &&
                  subProduct.isActive === true &&
                  subItemIdObj[`item${id}/${index}`] &&
                  subItemIdObj[`item${id}/${index}`].id.includes(
                    subProduct.itemId,
                  )
                ) {
                  // Add the sub-item ID to the new ID list
                  newIdList.push(subProduct.itemId);
                  if (
                    !newIdObj[`item${id}/${index}`].id.includes(
                      subProduct.itemId,
                    )
                  ) {
                    newIdObj[`item${id}/${index}`].id.push(subProduct.itemId);
                  }
                }
                if (subProduct["subProducts"]) {
                  subProduct["subProducts"].forEach((nestedSubProduct) => {
                    if (
                      subItemIdList.includes(nestedSubProduct.itemId) &&
                      nestedSubProduct.isActive === true &&
                      subItemIdObj[`item${id}/${index}`] &&
                      subItemIdObj[`item${id}/${index}`].id.includes(
                        nestedSubProduct.itemId,
                      )
                    ) {
                      if (
                        !newIdObj[`item${id}/${index}`].id.includes(
                          nestedSubProduct.itemId,
                        )
                      ) {
                        newIdObj[`item${id}/${index}`].id.push(
                          nestedSubProduct.itemId,
                        );
                      }
                      // Add the nested sub-item ID to the new ID list
                      newIdList.push(nestedSubProduct.itemId);
                    }
                  });
                }
              });
            }
          }
        }
      });
      const validateId = handleValidateId(newIdObj);
      setValidateId(validateId);

      // Update the ID lists and set the open state to true
      setIdList(newIdList);
      setInitialIdLists(newIdObj);
      setOpen(true);
    } catch (error) {
      // Log any errors that occur during the reordering process
      logError({ message: "Error during reordering", error });
    }
  };

  const handleConfirmReOrder = async () => {
    setIsLoadingButton(true);
    // Fetch menu information
    const menu = await getMenuInformation();

    if (menu) {
      const selectedItems = [];

      // Iterate through cart items
      cartItems.forEach((item, index) => {
        const {
          catId,
          catMenuId,
          discountIds,
          discountedPrice,
          id,
          menuId,
          quantity,
          subItems,
        } = item;

        // Check if the item is selected based on idList
        if (idList.includes(`item${id}/${index}`)) {
          const selectedItemObj =
            menu[`CM${catMenuId}`]["data"][`C${catId}`]["data"][`M${menuId}`];

          if (!_.isEmpty(selectedItemObj)) {
            let selectedSubItems = [];
            if (subItems) {
              selectedSubItems = subItems.filter((data1) =>
                idList.includes(data1.id),
              );
            }
            let title = "";
            let price = "";

            if (selectedItemObj["data"].length > 1) {
              const multipleDirectItems = selectedItemObj["data"];

              const filterData = multipleDirectItems.filter(
                (data1) => id === data1.itemId,
              );

              title = selectedItemObj.title + filterData[0].size;
              price = filterData[0].price;
            } else {
              const selectedItem = selectedItemObj["data"][0];
              title = selectedItemObj.title;
              price = selectedItem.price;
            }
            selectedItems.push({
              catId,
              catMenuId,
              discountIds,
              discountedPrice,
              id,
              menuId,
              name: title,
              price,
              quantity,
              subItems: selectedSubItems,
            });
          }
        }
      });

      if (!_.isEmpty(selectedItems)) {
        addItemToCart(selectedItems, menu);
      } else {
        setError("Please select lest one item.");
        setIsLoadingButton(false);
      }
    } else {
      setIsLoadingButton(false);
    }
  };

  /**
   *
   * Function to handle the change event when a checkbox is clicked
   */
  const handleChange = (event) => {
    if (!event.target.checked) {
      // If the checkbox is unchecked, remove the corresponding value from the idList
      const id = idList.filter((data) => data !== event.target.value);
      setIdList(id);
    } else {
      if (!idList.includes(event.target.value)) {
        // If the checkbox is checked and the value is not already present in the idList
        const cloneIdList = [...idList];
        cloneIdList.push(event.target.value);
        setIdList(cloneIdList);
      }
    }
  };

  const handleName = (data, index) => {
    if (validateId[`item${data.id}/${index}`]) {
      if (!validateId[`item${data.id}/${index}`].validate) {
        return (
          <Typography
            style={{ display: "flex", fontSize: "12px", alignItems: "center" }}
          >
            <StarIcon
              style={{
                fontSize: "10px",
                paddingRight: "4px",
                marginBottom: "1px",
              }}
            />
            Item has been amended due to changes in the menu.
          </Typography>
        );
      }
    } else {
      return (
        <Typography
          variant="caption"
          style={{ display: "flex", alignItems: "center" }}
        >
          <StarIcon style={{ fontSize: "10px" }} />
          Item has been amended due to changes in the menu.
        </Typography>
      );
    }
  };

  return (
    <>
      <AlertMessage message={error} setMessage={setError} severity="error" />
      {!!alertMessage ? (
        <AlertMessage
          message={alertMessage}
          setMessage={setAlertMessage}
          severity="error"
        />
      ) : (
        <WithDataLayout>
          <PageWrapper>
            <LoginDialog />
            <DialogDefault
              open={open}
              setOpen={setOpen}
              dialogTitle={"The following items are available to re-order"}
              handleSubmit={handleConfirmReOrder}
              disabled={_.isEmpty(idList)}
              isLoadingButton={isLoadingButton}
            >
              <div>
                <Grid container>
                  {!_.isEmpty(cartItemsClone) &&
                    cartItemsClone.map((data, index1) => (
                      <Grid
                        container
                        style={{ marginTop: "2px", placeItems: "center" }}
                      >
                        {handleName(data, index1)}

                        <Grid
                          item
                          xs={10}
                          style={
                            !initialIdLists[`item${data.id}/${index1}`]
                              ? {
                                  textDecoration: "line-through",
                                  fontWeight: "bold",
                                }
                              : { fontWeight: "bold" }
                          }
                        >
                          <Typography style={{ fontWeight: "bold" }}>
                            {data.name}
                          </Typography>
                        </Grid>
                        <Grid
                          item
                          xs={2}
                          style={{
                            display: "flex",
                            justifyContent: "end",
                          }}
                        >
                          <Checkbox
                            style={{ color: theme.palette.text.itemTextColor }}
                            value={`item${data.id}/${index1}`}
                            checked={
                              !initialIdLists[`item${data.id}/${index1}`]
                                ? false
                                : idList.includes(`item${data.id}/${index1}`)
                            }
                            disabled={
                              !initialIdLists[`item${data.id}/${index1}`]
                            }
                            onChange={handleChange}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </Grid>
                        {!_.isEmpty(data.subItems) && (
                          <Grid
                            container
                            style={{ marginTop: "4px", marginBottom: "4px" }}
                          >
                            {data.subItems.map((data1, index2) => (
                              <Grid container>
                                <Grid
                                  item
                                  xs={10}
                                  style={
                                    !initialIdLists[
                                      `item${data.id}/${index1}`
                                    ] ||
                                    !initialIdLists[
                                      `item${data.id}/${index1}`
                                    ].id.includes(data1.id)
                                      ? {
                                          textDecoration: "line-through",
                                          paddingLeft: "8px",
                                        }
                                      : { paddingLeft: "8px" }
                                  }
                                >
                                  <Typography variant="caption">
                                    {data1.name}
                                  </Typography>
                                </Grid>
                                <Grid
                                  item
                                  xs={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "end",
                                  }}
                                ></Grid>
                              </Grid>
                            ))}
                          </Grid>
                        )}
                      </Grid>
                    ))}
                </Grid>
              </div>
            </DialogDefault>
            <SecondaryThemeProvider>
              <ContentSection>
                <ContentContainer
                  noPadding
                  isMyOrdersPage
                  orderStatus={orderStatus}
                >
                  <Grid container>
                    <Hidden xsDown>
                      {!isLoading ? (
                        <Grid container>
                          <Grid item xs={12} sm={5}>
                            <div
                              style={{
                                display: "flex",
                                color: theme.palette.text.itemTextColor,
                                padding: "8px",
                              }}
                            >
                              <Button
                                style={{ color: "white" }}
                                onClick={redirectToHome}
                              >
                                <ArrowBackIcon
                                  style={{ paddingRight: "4px" }}
                                />
                                <Typography>Back</Typography>
                              </Button>
                            </div>
                            <div
                              className={
                                classes.cardWrapperSelectedOrderDetailsDesktop
                              }
                            >
                              <div className={classes.itemListing}>
                                {!_.isEmpty(cartItems) &&
                                  cartItems.map((item, i) => (
                                    <CartItemCard
                                      key={item.menuId}
                                      cartItemData={item}
                                      idx={i}
                                    />
                                  ))}
                              </div>

                              <PriceBreakdown
                                deliveryCharge={deliveryCost}
                                totalDiscount={discountTotal}
                                totalPaymentAmount={paymentAmount}
                                serviceCharge={serviceCharge}
                                deliveryType={deliveryType}
                                extraCharges={extraCharges}
                              />
                            </div>
                          </Grid>
                          <Grid item xs={12} sm={7}>
                            <div className={classes.headingStyle}>
                              <div>
                                <OrderStepper
                                  orderStatus={orderStatus}
                                  statusColor={getStatusColor(orderStatus)}
                                  deliveryIsAsap={deliveryIsAsap}
                                />
                                <PageHeading
                                  orderNumber={orderNumber || "NA"}
                                  orderTime={dueTime}
                                  deliveryType={deliveryType}
                                  orderStatus={orderStatus}
                                  deliveryAddress={deliveryAddress}
                                  statusColor={getStatusColor(orderStatus)}
                                />
                              </div>

                              <RestaurantContactInfo
                                deliveryAddress={deliveryAddress}
                                customer={customer}
                                deliveryType={deliveryType}
                                businessDisplayName={businessDisplayName}
                                addressFormatted={addressFormatted}
                                contactNo={contactNo}
                              />
                              <ReOrder handleReOrder={handleReOrder} />
                            </div>
                          </Grid>
                        </Grid>
                      ) : (
                        <SkeletonLoading />
                      )}
                    </Hidden>

                    <Hidden smUp>
                      {!isLoading ? (
                        <Grid item xs={12}>
                          <div
                            style={{
                              display: "flex",
                              color: "white",
                              padding: "8px",
                            }}
                          >
                            <Button
                              style={{ color: "white" }}
                              onClick={redirectToHome}
                            >
                              <ArrowBackIcon style={{ paddingRight: "4px" }} />
                              <Typography>Back</Typography>
                            </Button>
                          </div>
                          <OrderStepper
                            orderStatus={orderStatus}
                            statusColor={getStatusColor(orderStatus)}
                            deliveryIsAsap={deliveryIsAsap}
                          />
                          <PageHeading
                            orderNumber={orderNumber || "NA"}
                            orderTime={dueTime}
                            deliveryType={deliveryType}
                            orderStatus={orderStatus}
                            deliveryAddress={deliveryAddress}
                            statusColor={getStatusColor(orderStatus)}
                          />

                          <div>
                            <div
                              className={
                                classes.cardWrapperSelectedOrderDetailsMobile
                              }
                            >
                              {!_.isEmpty(cartItems) &&
                                cartItems.map((item, i) => (
                                  <CartItemCard
                                    key={item.menuId}
                                    cartItemData={item}
                                    idx={i}
                                  />
                                ))}
                            </div>

                            <PriceBreakdown
                              deliveryCharge={deliveryCost}
                              totalDiscount={discountTotal}
                              totalPaymentAmount={paymentAmount}
                              serviceCharge={serviceCharge}
                              deliveryType={deliveryType}
                              extraCharges={extraCharges}
                            />

                            <RestaurantContactInfo
                              deliveryAddress={deliveryAddress}
                              customer={customer}
                              deliveryType={deliveryType}
                              businessDisplayName={businessDisplayName}
                              addressFormatted={addressFormatted}
                              contactNo={contactNo}
                            />
                            <ReOrder handleReOrder={handleReOrder} />
                          </div>
                        </Grid>
                      ) : (
                        <SkeletonLoading />
                      )}
                    </Hidden>
                  </Grid>
                </ContentContainer>
              </ContentSection>
            </SecondaryThemeProvider>
          </PageWrapper>
        </WithDataLayout>
      )}
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: bindActionCreators({ addItemToCartAsync }, dispatch),
});

export default connect(null, mapDispatchToProps)(NewOrderStatusPage);

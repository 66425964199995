import React from "react";

import {
  Button,
  CircularProgress,
  makeStyles,
  SvgIcon,
  Typography,
} from "@material-ui/core";

const useStyles = makeStyles(() => ({
  root: {
    textTransform: "none",
    minWidth: 220,
    maxHeight: 40,
    display: "flex",
    justifyContent: "space-between",

    // color: theme.palette.action.active,
    // backgroundColor: theme.palette.background.paper,
    // borderRadius: 0,
    // "&:hover": {
    //   backgroundColor: theme.palette.action.hover,
    // },
  },
}));

const SocialSignInButton = ({
  handleClick,
  logoSrc,
  labelText,
  isLoading,
  alt = "alt",
  disabled,
}) => {
  const classes = useStyles();
  return (
    <Button
      variant="contained"
      size="large"
      onClick={handleClick}
      classes={{ root: classes.root }}
      disabled={isLoading || disabled}
    >
      {typeof logoSrc === "string" ? (
        <img
          src={logoSrc}
          alt={alt}
          style={{ width: 24, height: 24, marginRight: 8 }}
        />
      ) : (
        <SvgIcon>{logoSrc}</SvgIcon>
      )}
      {isLoading ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        <Typography variant="subtitle2">{labelText}</Typography>
      )}
    </Button>
  );
};

export default SocialSignInButton;
// "https://developers.google.com/identity/images/g-logo.png"

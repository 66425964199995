/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-alert */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect } from "react";

import qs from "query-string";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";

import { asyncLocationInitData } from "../../app/redux/actions";
import { setIsDineIn } from "../../redux/actions/global";
import { getCombinedLocationInfo } from "../../services/locationService";
// import { checkOrder } from "../../services/orderService";
// import { getLocalStore } from "../../utils/storage/localStorage";
import { logError, logInfo } from "../../services/logging";
import {
  fetchCatMenuListAsync,
  fetchCatMenuOptionsAsync,
} from "../@pages/storeHome/CatMenu/redux/actions";
import { setLocationId } from "../@pages/storeHome/MyOrder/redux/actions";
import {
  asyncFetchOpenTimes,
  asyncFetchOrderRelatedInfo,
  asyncFetchStoreInfo,
  setOtherStoreInfo,
} from "../@pages/storeHome/redux/actions";

const withInitDataFetch = (WrappedComponent) => (props) => {
  const dispatch = useDispatch();
  const params = useParams();
  const location = useLocation();
  // const history = useHistory();
  const { isDineIn } = qs.parse(location.search, { parseBooleans: true });

  // const updateOrderId = async () => {
  //   try {
  //     let orderId = getLocalStore("orderId");
  //     if (orderId) {
  //       orderId = JSON.parse(orderId);
  //       try {
  //         const res = await checkOrder(params.locationId, orderId);
  //         const { status } = res.data.data;
  //         if (status !== "started" || status === "checkout") {
  //           history.push(`/location/${params.locationId}/shop`);
  //         }
  //       } catch (error) {
  //         history.push(`/location/${params.locationId}/shop`);
  //       }
  //     } else {
  //       history.push(`/location/${params.locationId}/shop`);
  //     }
  //   } catch (error) {
  //     alert(
  //       "Error checking order status. Please check your connection",
  //     );
  //   }
  // };

  const fetchCombinedStoreInfo = async () => {
    try {
      const res = await getCombinedLocationInfo(params.locationId);
      const {
        isOrderingActive,
        isPreOrderActive,
        serviceCharge,
        carrierBagCharge,
      } = res.data.data;

      logInfo({
        message: "Combined location info",
        isOrderingActive,
        isPreOrderActive,
        serviceCharge: isDineIn ? "0.00" : serviceCharge,
        carrierBagCharge,
      });

      dispatch(
        setOtherStoreInfo({
          isOrderingActive,
          isPreOrderActive,
          serviceCharge: isDineIn ? "0.00" : serviceCharge,
          carrierBagCharge,
        }),
      );
    } catch (error) {
      logError({ message: "Error fetching combined location info", error });
      alert("Error fetching store data. Please check your connection");
    }
  };

  useEffect(() => {
    dispatch(fetchCatMenuListAsync(params.locationId));
    dispatch(asyncFetchOrderRelatedInfo(params.locationId));
    dispatch(asyncLocationInitData(params.locationId));
    dispatch(asyncFetchStoreInfo(params.locationId));
    dispatch(asyncFetchOpenTimes(params.locationId));
    dispatch(fetchCatMenuOptionsAsync(params.locationId));
    fetchCombinedStoreInfo();
    dispatch(setIsDineIn());
    // updateOrderId();

    dispatch(setLocationId(params.locationId));
  }, [dispatch, params.locationId]);

  return <WrappedComponent {...props} />;
};

export default withInitDataFetch;

import React from "react";

import {
  Divider,
  Grid,
  makeStyles,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useCallback } from "react";
import ButtonDefault from "../../common/buttons/ButtonDefault";
import firebase from "firebase";
import { logError } from "../../../services/logging";
import MuiAlert from "@material-ui/lab/Alert";
import { useSelector } from "react-redux";
import FacebookSignIn from "./FacebookSignIn";
import GoogleSignIn from "./GoogleSignIn";
import TextFieldCommon from "../../common/textFields/TextFieldCommon";

const ERROR_MESSAGE_ACCOUNT_ALREADY_EXIST =
  "An account already exists with the same email address via Google sign-in. Please use Google to sign-in";

const ERROR_MESSAGE_IDB_DATABASE =
  "Oops! Something went wrong while trying to sign you in. Please try again in a few seconds. If the issue persists, you can refresh your browser, use a different sign-in method, or continue as a guest. We apologise for the inconvenience.";

const useStyles = makeStyles((theme) => ({
  makeMiddle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  root: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  textStyle: {},
}));

const SignInPage = () => {
  const [customer, setCustomer] = useState({
    email: "",
    password: "",
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isNeedDisabled, setIsNeedDisabled] = useState(false);

  const theme = useTheme();
  const { register, errors, trigger } = useForm();
  const [authError, setAuthError] = useState("");

  const trimValues = useCallback(async () => {
    setCustomer({
      email: customer.email.trim(),
      password: customer.password.trim(),
    });
  }, [customer]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "marketing") {
      if (value === "true") {
        setCustomer({ ...customer, [name]: false });
      } else {
        setCustomer({ ...customer, [name]: true });
      }
    } else {
      setCustomer({ ...customer, [name]: value });
    }
  };

  const handleLogin = async () => {
    firebase
      .auth()
      .setPersistence(firebase.auth.Auth.Persistence.LOCAL)
      .then(() => {
        setIsLoading(true);
        setIsNeedDisabled(true);
        // Existing and future Auth states are now persisted in the current
        // session only. Closing the window would clear any existing state even
        // if a user forgets to sign out.
        // ...
        // New sign-in will be persisted with session persistence.
        firebase
          .auth()
          .signInWithEmailAndPassword(customer.email, customer.password)
          .then(function (result) {
            // result.user.tenantId should be ‘TENANT_PROJECT_ID’.
            firebase.auth().onAuthStateChanged((firebaseUser) => {
              if (firebaseUser) {
                firebaseUser.getIdToken(true).then(function (idToken) {
                  setTimeout(() => {
                    setIsLoading(false);
                    setIsNeedDisabled(false);

                    // history.push("/accounts/apps");
                  }, 2000);
                });
              }
            });
          })
          .catch(function (error) {
            // Handle Errors here.
            const errorCode = error.code;
            const errorMessage = error.message;
            const isIdbError = errorMessage.includes("IDBDatabase");

            const message = isIdbError
              ? ERROR_MESSAGE_IDB_DATABASE
              : error.message;
            setAuthError(message);
            // The email of the user's account used.
            const { email } = error;
            // The firebase.auth.AuthCredential type that was used.
            const { credential } = error;
            logError({
              message:
                "Firebase AnonymousSignIn: Error login user with AnonymousSignIn",
              error,
              credential,
              email,
              errorCode,
            });
            setIsLoading(false);
            setIsNeedDisabled(false);
          });
      })
      .catch((error) => {
        // Handle Errors here.
        const errorCode = error.code;
        const errorMessage = error.message;
        const isIdbError = errorMessage.includes("IDBDatabase");

        const message = isIdbError ? ERROR_MESSAGE_IDB_DATABASE : error.message;
        setAuthError(message);
        // The email of the user's account used.
        const { email } = error;
        // The firebase.auth.AuthCredential type that was used.
        const { credential } = error;
        logError({
          message:
            "Firebase AnonymousSignIn: Error login user with AnonymousSignIn",
          error,
          credential,
          email,
          errorCode,
        });
        setIsLoading(false);
        setIsNeedDisabled(false);
      });
  };

  const handleContinue = async () => {
    setAuthError("");
    // Trim white spaces from the form values.
    trimValues();

    // Trigger form validation using the useForm trigger function.
    // This will validate all the registered fields and return a boolean indicating if the form is valid.
    const isValid = await trigger();

    // If the form is not valid, return early and do not proceed.
    if (!isValid) return;

    handleLogin();
  };
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container>
        <Grid item xs={12} className={classes.textStyle}>
          <Typography
            variant="h6"
            gutterBottom
            align="center"
            style={{ fontWeight: "bold" }}
            className={classes.textStyle}
          >
            Sign In
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <TextFieldCommon
            id="email"
            name="email"
            label="Email address"
            value={customer.email}
            variant="outlined"
            onChange={handleChange}
            fullWidth
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 30,
            })}
            helperText={errors.email ? "Please enter email address" : ""}
            error={errors.email?.type}
          />
        </Grid>
        <Grid item xs={3}></Grid> <Grid item xs={3}></Grid>
        <Grid item xs={6}>
          <TextFieldCommon
            id="password"
            name="password"
            label="Password"
            type="password"
            value={customer.password}
            variant="outlined"
            onChange={handleChange}
            fullWidth
            inputRef={register({
              required: true,
              minLength: 2,
              maxLength: 30,
            })}
            helperText={errors.password ? "Please enter password" : ""}
            error={errors.password?.type}
          />
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6} style={{ display: "flex", justifyContent: "start" }}>
          <Typography
            variant="body2"
            gutterBottom
            align="center"
            style={{ fontWeight: "bold" }}
            className={classes.textStyle}
          >
            Forgot Password?
          </Typography>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6} style={{ marginTop: "24px" }}>
          <ButtonDefault
            bgColor={"black"}
            textColor={"white"}
            onClick={handleContinue}
            fullWidth
          >
            <Typography style={{ fontWeight: "bold", fontSize: "18px" }}>
              LOGIN
            </Typography>
          </ButtonDefault>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6} style={{ marginTop: "8px", alignItems: "center" }}>
          <Grid container style={{ display: "flex", alignItems: "center" }}>
            <Grid item xs={4}>
              <Divider
                style={{
                  margin: "4px 0",
                  backgroundColor: theme.palette.primary.contrastText,
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <Typography
                style={{
                  fontSize: "18px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className={classes.textStyle}
              >
                or LogIn in with
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Divider
                style={{
                  margin: "4px 0",
                  backgroundColor: theme.palette.primary.contrastText,
                }}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={3}></Grid>
        <Grid item xs={6} style={{ marginTop: "24px" }}>
          <div style={{ padding: 16 }}>
            <div style={{ padding: "4px 0" }}>
              {authError && (
                <MuiAlert severity="error" elevation={6} variant="outlined">
                  {authError}
                </MuiAlert>
              )}
            </div>

            <Grid
              container
              spacing={2}
              className={classes.makeMiddle}
              style={{ marginTop: 8 }}
            >
              <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
                <GoogleSignIn
                  setAuthError={setAuthError}
                  isNeedDisabled={isNeedDisabled}
                />
              </Grid>
              <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
                <FacebookSignIn
                  setAuthError={setAuthError}
                  isNeedDisabled={isNeedDisabled}
                />
              </Grid>
              {/* <Grid item sm={6} md={6} xs={12} className={classes.makeMiddle}>
            <FirebaseAnonymousSignIn
              setAuthError={setAuthError}
              isLoading={isLoading}
            />
          </Grid> */}
            </Grid>

            <span style={{ margin: 8 }} />

            {/* <MicrosoftSignIn />
        <span style={{ margin: 8 }} />
        <FacebookSignIn /> */}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default SignInPage;

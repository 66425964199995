import {
  FETCH_CAT_MENU_LIST_PENDING,
  FETCH_CAT_MENU_LIST_SUCCESS,
  FETCH_CAT_MENU_LIST_ERROR,
  FETCH_CAT_MENU_OPTIONS_PENDING,
  FETCH_CAT_MENU_OPTIONS_SUCCESS,
  FETCH_CAT_MENU_OPTIONS_ERROR,
  SET_CAT_MENU_LIST_ALL,
  SET_ACTIVE_CAT_MENU,
} from "./actions";

export const CatMenuListInitialState = {
  catMenuList: [],
  catMenuListAll: [],
  activeCatMenu: {},
  catMenuOptions: {},
  isFetching: false,
  isFetchingOptions: false,
  error: null,
  errorOptions: null,
};

export const CatMenuListReducer = (state = CatMenuListInitialState, action) => {
  switch (action.type) {
    case FETCH_CAT_MENU_LIST_PENDING:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_CAT_MENU_LIST_SUCCESS:
      return {
        ...state,
        isFetching: false,
        catMenuList: action.payload,
      };
    case FETCH_CAT_MENU_LIST_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };

    // cat menu options
    case FETCH_CAT_MENU_OPTIONS_PENDING:
      return {
        ...state,
        isFetchingOptions: true,
      };
    case FETCH_CAT_MENU_OPTIONS_SUCCESS:
      return {
        ...state,
        isFetching: false,
        catMenuOptions: action.payload,
      };
    case FETCH_CAT_MENU_OPTIONS_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case SET_CAT_MENU_LIST_ALL:
      return {
        ...state,
        catMenuListAll: action.payload,
      };
    case SET_ACTIVE_CAT_MENU:
      return {
        ...state,
        activeCatMenu: action.payload,
      };
    default:
      return state;
  }
};

import _ from "lodash";
import isArray from "lodash/isArray";

import { logError, logInfo } from "../../../../../services/logging";
import { getCatMenuItems, getCatMenuOptions } from "../../../../../services/menuService";
import { clearCookies } from "../../../../../utils/cookies";
import i18next from "../../../../../utils/i18n/i18next";
import { getLocalStore, setLocalStore } from "../../../../../utils/storage/localStorage";
import { setCategoryList } from "../../Menu/redux/actions";

export const FETCH_CAT_MENU_LIST_PENDING = "FETCH_CAT_MENU_LIST_PENDING";
export const FETCH_CAT_MENU_LIST_SUCCESS = "FETCH_CAT_MENU_LIST_SUCCESS";
export const FETCH_CAT_MENU_LIST_ERROR = "FETCH_CAT_MENU_LIST_ERROR";

export const FETCH_CAT_MENU_OPTIONS_PENDING = "FETCH_CAT_MENU_OPTIONS_PENDING";
export const FETCH_CAT_MENU_OPTIONS_SUCCESS = "FETCH_CAT_MENU_OPTIONS_SUCCESS";
export const FETCH_CAT_MENU_OPTIONS_ERROR = "FETCH_CAT_MENU_OPTIONS_ERROR";
export const SET_CAT_MENU_LIST_ALL = "SET_CAT_MENU_LIST_ALL";
export const SET_ACTIVE_CAT_MENU = "SET_ACTIVE_CAT_MENU";

function fetchCatMenuListPending() {
  return {
    type: FETCH_CAT_MENU_LIST_PENDING,
  };
}

export function fetchCatMenuListSuccess(items) {
  return {
    type: FETCH_CAT_MENU_LIST_SUCCESS,
    payload: items,
  };
}

export function setCatMenuListAll(items) {
  return {
    type: SET_CAT_MENU_LIST_ALL,
    payload: items,
  };
}

function fetchCatMenuListError(error) {
  return {
    type: FETCH_CAT_MENU_LIST_ERROR,
    error,
  };
}

const invalidateCache = (version) => {
  try {
    const menuVersion = getLocalStore("menuVersion");
    if (_.isNumber(menuVersion) && menuVersion !== version) {
      localStorage.clear();
      clearCookies();
      logInfo("ERROR: MENU_VERSION_MISS_MATCH_ERROR: Menu version miss match")
      window.location.reload();
    }
  } catch (error) {
    console.log("Error invalidating cache");
  }
}

export const fetchCatMenuListAsync = (locationId) => async (dispatch) => {
  dispatch(fetchCatMenuListError(""));
  dispatch(fetchCatMenuListPending());
  try {
    const res = await getCatMenuItems(locationId);
    const menu = res.data.data;

    // invalidate caches if version miss match
    invalidateCache(res.data.version)

    setLocalStore("menuVersion", res.data.version)
    let catMenus = Object.keys(menu);
    catMenus = catMenus.map((c) => menu[c]);
    const initCat = Object.keys(catMenus[0].data).map(
      (c) => catMenus[0].data[c],
    );
    catMenus = catMenus.filter((cm) => !!cm.data);
    // filter active catMenus
    catMenus = catMenus.filter((cm) => !!cm.isActive);

    if (isArray(catMenus)) {
      dispatch(setCategoryList(initCat));
      dispatch(fetchCatMenuListSuccess(catMenus));
      return dispatch(setCatMenuListAll(catMenus));
    }
    return dispatch(fetchCatMenuListError(i18next.t("errors.unexpected")));
  } catch (error) {
    logError({ message: "Error fetching menu items data", error });
    return dispatch(fetchCatMenuListError(i18next.t("errors.networkFailed")));
  }
};

function fetchCatMenuOptionsPending() {
  return {
    type: FETCH_CAT_MENU_OPTIONS_PENDING,
  };
}

function fetchCatMenuOptionsSuccess(items) {
  return {
    type: FETCH_CAT_MENU_OPTIONS_SUCCESS,
    payload: items,
  };
}

function fetchCatMenuOptionsError(error) {
  return {
    type: FETCH_CAT_MENU_OPTIONS_ERROR,
    error,
  };
}

export function setActiveCatMenu(catMenu) {
  return {
    type: SET_ACTIVE_CAT_MENU,
    payload: catMenu,
  };
}

export const fetchCatMenuOptionsAsync = (locationId) => async (dispatch) => {
  dispatch(fetchCatMenuOptionsError(""));
  dispatch(fetchCatMenuOptionsPending());
  try {
    const res = await getCatMenuOptions(locationId);
    const menuOptions = res.data.data.options;
    logInfo({ message: "menu options" });
    // let catMenus = Object.keys(menu);
    // catMenus = catMenus.map((c) => menu[c]);
    // const initCat = Object.keys(catMenus[0].data).map(
    //   (c) => catMenus[0].data[c],
    // );

    // catMenus = catMenus.filter((cm) => !!cm.data);
    if (!_.isEmpty(menuOptions)) {
      return dispatch(fetchCatMenuOptionsSuccess(menuOptions));
    }
    return dispatch(fetchCatMenuOptionsError(i18next.t("errors.unexpected")));
  } catch (error) {
    logError({ message: "Error fetching menu options data", error });
    return dispatch(fetchCatMenuOptionsError(i18next.t("errors.networkFailed")));
  }
};
